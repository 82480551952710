<template>
  <div class="pa-3">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center" class="mt-0">
        <v-col cols="12" lg="3">
          <v-text-field
            required
            class="schedule-name"
            v-model="data.name"
            placeholder="請輸入排程名稱"
            hide-details
            append-icon="mdi-pencil"
            :rules="[
              (v) => !!v || '必填',
              (v) => {
                const pattern = /^[_0-9a-zA-Z.\s\u4e00-\u9fa5]+$/
                return pattern.test(v) || '格式不符'
              }
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card
            v-permission-with-city="['keelung']"
            v-if="actionType === 'temperature'"
            class="error--text text-caption"
            outlined
            color="transparent"
          >
            1.使用溫度控制排程前，請確認冷氣開啟並且處於冷氣模式之下。 (
            電驛狀態為 [ 接上電驛 ]。或者為 [ 交由卡機控制 ]，並且插卡)<br />
            2.電驛未接上、冷氣未開啟、非冷氣模式之下。溫度控制排程可能失效。
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" class="text-center">
          <v-btn-toggle
            rounded
            color="grey"
            borderless
            class="schedule-type-btn"
            v-model="data.type"
            mandatory
          >
            <v-tooltip top color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  value="date"
                  :disabled="id && data.type === 'week'"
                  >例外日期</v-btn
                >
              </template>
              <span>例外日期為最優先，當天其他排程皆會失效</span>
            </v-tooltip>
            <v-btn large value="week" :disabled="id && data.type === 'date'">
              每週循環
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" lg="3" class="text-right">
          <v-btn
            text
            depressed
            class="rounded white pa-2"
            color="primary"
            style="min-width: unset"
            @click="saveData()"
            :disabled="!valid"
          >
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="!overlay">
        <v-col cols="12" lg="3">
          <SelectGroup v-model="data.groupIdList" :tag-id="tagId" />
        </v-col>
        <v-col cols="12" lg="9">
          <v-card elevation="0" class="calendar-card pb-2">
            <CycleCalendar
              v-if="data.type === 'week'"
              v-model="data.action"
              :action-type="actionType"
            />
            <SingleDayCalendar
              v-else-if="data.type === 'date'"
              v-model="data.action"
              :action-type="actionType"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Schedule from '@/api/ems/Schedule'
import { mapActions, mapGetters } from 'vuex'
import CycleCalendar from './cycleCalendar'
import SingleDayCalendar from './singleDayCalendar'
import SelectGroup from './selectGroup'

export default {
  name: 'ScheduleEditOrCreate',
  mixins: [],
  components: {
    CycleCalendar,
    SingleDayCalendar,
    SelectGroup
  },
  props: {
    id: {
      type: String
    },
    tagId: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      default: 'relay'
    }
  },
  data: function () {
    return {
      overlay: false,
      valid: false,
      data: {
        name: null,
        type: 'week',
        actionType: this.actionType === 'relay' ? 0 : 1,
        action: [],
        groupIdList: []
      }
    }
  },
  computed: {
    ...mapGetters({
      typeOption: 'schedule/typeOption'
    }),
    editType() {
      return this.id ? 'update' : 'create'
    }
  },
  watch: {
    'data.type'(newVal, oldVal) {
      if (!this.id) {
        this.data.action = []
      }
    }
  },
  created: function () {},
  mounted: function () {
    if (this.id) {
      this.getSchedule()
    }
  },
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    getSchedule() {
      this.overlay = true
      Schedule.show(this.id)
        .then((response) => {
          this.data = response.data
        })
        .finally(() => {
          setTimeout(() => {
            this.overlay = false
          }, 2000)
        })
    },
    validate() {
      let errMsg = null

      if (!this.valid) {
        errMsg = `請輸入排程名稱`
      } else if (!this.data.name) {
        errMsg = `請輸入排程名稱`
      } else if (!this.data.groupIdList || this.data.groupIdList.length === 0) {
        errMsg = `請選擇對象`
      } else if (!this.data.action || this.data.action.length === 0) {
        errMsg = `請設定排程內容`
      }

      if (errMsg) {
        this.showMsg({
          message: errMsg,
          color: 'error',
          closable: true,
          time: 2000
        })
        return false
      }

      return true
    },
    saveData() {
      if (!this.validate()) {
        return
      }
      this.overlay = true
      const saveFun =
        this.editType === 'update'
          ? this.updateSchedule()
          : this.createSchedule()
      saveFun
        .then(() => {
          this.showMsg({
            message: `設定成功`,
            closable: true,
            time: 2000
          })
          this.$router.push({ path: `/school/${this.tagId}/schedule` })
        })
        .catch((error) => {
          this.showMsg({
            message: error.response.data.message || `設定失敗`,
            color: 'error',
            closable: true,
            time: 5000
          })
        })
        .finally(() => {
          this.overlay = false
        })
    },
    updateSchedule() {
      return Schedule.update(this.id, this.data)
    },
    createSchedule() {
      return Schedule.create(this.tagId, this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-name.v-text-field::v-deep {
  input {
    color: var(--v-accent-base);
    font-size: 1.2rem;
    font-weight: bold;
  }
  .v-input__control > .v-input__slot:before {
    border-width: 0;
  }
}

.schedule-type-btn::v-deep {
  .v-btn--has-bg {
    background-color: #d6e1f2;
    color: #889bb7;
    font-weight: bolder;
  }
  &.v-btn-toggle > .v-btn.v-item--active {
    color: var(--v-primary-base);
    background-color: #fff;
    box-shadow: 0 0 5px #c5c5c5;
    z-index: 1;
    &::before {
      opacity: 0;
    }
  }
}
.calendar-card::v-deep {
  background-color: #ffffff88;
  .v-calendar-daily {
    background-color: transparent;
  }
}
</style>
