var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{staticClass:"schedule-name",attrs:{"required":"","placeholder":"請輸入排程名稱","hide-details":"","append-icon":"mdi-pencil","rules":[
            function (v) { return !!v || '必填'; },
            function (v) {
              var pattern = /^[_0-9a-zA-Z.\s\u4e00-\u9fa5]+$/
              return pattern.test(v) || '格式不符'
            }
          ]},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[(_vm.actionType === 'temperature')?_c('v-card',{directives:[{name:"permission-with-city",rawName:"v-permission-with-city",value:(['keelung']),expression:"['keelung']"}],staticClass:"error--text text-caption",attrs:{"outlined":"","color":"transparent"}},[_vm._v(" 1.使用溫度控制排程前，請確認冷氣開啟並且處於冷氣模式之下。 ( 電驛狀態為 [ 接上電驛 ]。或者為 [ 交由卡機控制 ]，並且插卡)"),_c('br'),_vm._v(" 2.電驛未接上、冷氣未開啟、非冷氣模式之下。溫度控制排程可能失效。 ")]):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"3"}},[_c('v-btn-toggle',{staticClass:"schedule-type-btn",attrs:{"rounded":"","color":"grey","borderless":"","mandatory":""},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}},[_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","value":"date","disabled":_vm.id && _vm.data.type === 'week'}},'v-btn',attrs,false),on),[_vm._v("例外日期")])]}}])},[_c('span',[_vm._v("例外日期為最優先，當天其他排程皆會失效")])]),_c('v-btn',{attrs:{"large":"","value":"week","disabled":_vm.id && _vm.data.type === 'date'}},[_vm._v(" 每週循環 ")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","lg":"3"}},[_c('v-btn',{staticClass:"rounded white pa-2",staticStyle:{"min-width":"unset"},attrs:{"text":"","depressed":"","color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.saveData()}}},[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)],1)],1),(!_vm.overlay)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('SelectGroup',{attrs:{"tag-id":_vm.tagId},model:{value:(_vm.data.groupIdList),callback:function ($$v) {_vm.$set(_vm.data, "groupIdList", $$v)},expression:"data.groupIdList"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-card',{staticClass:"calendar-card pb-2",attrs:{"elevation":"0"}},[(_vm.data.type === 'week')?_c('CycleCalendar',{attrs:{"action-type":_vm.actionType},model:{value:(_vm.data.action),callback:function ($$v) {_vm.$set(_vm.data, "action", $$v)},expression:"data.action"}}):(_vm.data.type === 'date')?_c('SingleDayCalendar',{attrs:{"action-type":_vm.actionType},model:{value:(_vm.data.action),callback:function ($$v) {_vm.$set(_vm.data, "action", $$v)},expression:"data.action"}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }